import { Text } from '@makerdao/ui-components-core'
import styled from 'styled-components'

const FTBaseText = styled(Text)`
  font-family: 'FT Base', -apple-system, system-ui, BlinkMacSystemFont,
    'SF Pro Text', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: ${props => props.letterSpacing || '0.3px'};
  color: ${props => props.color || '#000'};
`

export default FTBaseText
