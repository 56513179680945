import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Markdown from '../components/markdown'
import { Box } from '@makerdao/ui-components-core'
import Text from '../components/text'
import PageWithTOC from '../components/pageWithTOC'
import { getGenericPageTitle } from '../i18n'

export default ({ data, pageContext: { locale } }) => {
  const { markdownRemark } = data
  const { html, headings, frontmatter } = markdownRemark
  return (
    <Layout
      locale={locale}
      meta={{ title: getGenericPageTitle('Privacy Policy', locale) }}
      fixedMobileMenu={true}
    >
      <Box mt="4.5rem">
        <PageWithTOC headings={headings}>
          <Box mb="3rem">
            <h1 style={{ fontSize: '3.5rem', lineHeight: '4.2rem' }}>
              Privacy Policy
            </h1>
            <p>
              <Text fontSize="1.6rem" color="#bfbfbf">
                Last Updated: {frontmatter.lastUpdated}
              </Text>
            </p>
          </Box>
          <Markdown dangerouslySetInnerHTML={{ __html: html }} />
        </PageWithTOC>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query PrivacyPage {
    markdownRemark(fileAbsolutePath: { glob: "**/privacyPolicy.md" }) {
      html
      frontmatter {
        lastUpdated
      }
      headings {
        value
        depth
      }
    }
  }
`
